.navBrand{
    /* border: solid black; */
    width: 21%;  
}

.fas{
    cursor: pointer;
    margin-right: 5%;
}

.facebookProfilePicture{
    border-radius: 100%;
    float: right;
    width:100%;
    height: 100%;
}

.googleProfilePicture{
    border-radius: 100%;
    float: right;
    width:60%;
}

.myNav{
    /* background-color: rgba(40, 89, 152, .85); */
    /* background: linear-gradient(rgba(32, 71, 122, 0.85), rgba(40, 89, 152, .9)); */
    background: linear-gradient(rgba(255, 156, 8, 0.85),rgba(254, 178, 60, 0.9)); 
    height: 8vh;
}

.nav-menu{
    display: flex;
    flex-direction: column;
    width: 100%;
    /* height: 500px; */
    position: absolute;
    top: 7.0vh;
    left: -100%;
    opacity: 1;
    transition: all 500ms ease;
}

.nav-menu.active{
    /* background: rgba(36, 78, 135, .99); */
    background: linear-gradient(rgba(255, 156, 8, 0.85),rgba(254, 178, 60, 0.9)); 
    left: 0;
    opacity: 1;
    transition: all 500ms ease;
    z-index: 1;
    transform: translate(0%, 1vh);
}

.loginButton{
    max-width: 20%;
    max-height: 70%;
    cursor: pointer;
}

.nav-link{
    text-align: center;
    color: black;
    padding: 2rem;
    width: 100%;
    cursor: pointer;
    display: table;
}

.nav-link:hover{
    background-color: rgb(248, 149, 0);
    color: white;
    border-radius: 0;
    transition: 500ms;
}

.cart{
    float: right;
    display: grid;
    grid-template-columns: 50% 50%;
    width: 10%;
}

.cartIcon{
    max-width: 100%;
}

.cartAmount{
    text-align: left;
}

.cartAmount p{
    text-align: center; 
    background-color: rgb(248, 149, 0);
    width: auto;
    border-radius: 15%;
}








