.paragraph{
    padding: 1%; 
    border-radius: 5px;
    background-color: rgba(245, 222, 179, .5);
    margin-bottom: 1%;
    display: grid; 
    grid-template-columns: 50% 50%;
}
.paragraphPicture{
    width: 40%;
}
.headerImageWrapper{
    padding: none;
    display: grid;
    grid-template-columns: 0% 100% 0%;
}
.headerLogoWrapper{
    left: 0;
    width: 15%;
    top: 5vh;
    left: 50%;
    margin-left: -7%; 
    position: absolute;
}

.imageWrapper{
    display: block; 
    text-align: center;
}

.mainPicture{
    border: 100%;
    width: 100%;
    z-index: -10;
}

.left{
}
.center{
}
.right{
}

.contentWrapper{
    position: absolute; 
    width: 100%;
    display: grid;
    grid-template-columns: auto 100% auto;
}

@media all 
and (max-device-width: 700px){

    .paragraph{
        grid-template-columns: 100%;
    }
    .headerLogoWrapper{
        margin-left: -17%;
        width: 35%;
    }

}
