.inventory{
    width: 100%;
    height: 100%;
    /* display: grid;
    grid-template-columns: 33% 33% 33%;
    column-gap: 1%; */

    -moz-column-count:3;
    -moz-column-gap: 3%;
    -moz-column-width: 30%;
    -webkit-column-count:3;
    -webkit-column-gap: 3%;
    -webkit-column-width: 30%;
    column-count: 3;
    column-gap: 3%;
    column-width: 30%;
}
.item{
    /* height: 25%;
    width: 40%; */
    padding: 1%; 
    border-radius: 5px;
    background-color: rgba(245, 222, 179, .5);
    margin-bottom: 5%;
    display: grid;
    grid-template-columns: 50% 50%;
}

.quantity{
    margin-left: 1%; 
    margin-bottom: 1%; 
    display: grid;
    grid-template-columns: 20% 60% 20%;
}

.sizes{
    margin-left: 1%; 
    display: grid;
    grid-template-columns: auto auto auto auto auto;
}

.size{
    margin-right: 3%; 
    border-radius: 9%; 
    background-color: rgb(248, 149, 0);
    cursor: pointer;
}

.selected{
    background-color: rgb(207, 130, 14);
}

.addCart{
    margin-left: 1%; 
    width: 100%;
}

.itemPicture{
    max-width: 100%;
    max-height: 100%;
}

@media all 
and (max-device-width: 700px){
    .inventory{
        display: grid;
    }
    .item{
        width: 100%;
    }

}
