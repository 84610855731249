body{
    background-color: #f1f5fb;
    text-align: center;
    background-image: url("./resources/images/HoneyComb-Background.png");
    background-repeat: repeat;
    height: 100%; 
    width: 100%; 
    background-size: auto;
    background-position: center;
}

.pageBody{
    padding: 10px;
}

*{
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'Montserrat', sans-serif;
}

